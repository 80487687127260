/**
 * Enable Smooth Scrolling Anchors
 */
const SmoothScrollAnchors = () => {
  // Prepare links for smooth scrolling
  var links = document.getElementsByTagName('a')

  for (var i = 0; i < links.length; i++) {
    var link = links[i]

    if (
      link.href &&
      link.href.indexOf('#') !== -1 &&
      (link.pathname === window.location.pathname ||
        '/' + link.pathname === window.location.pathname) &&
      link.search === window.location.search
    ) {
      // Attach function to eligible links
      link.onclick = scrollAnchors
    }
  }

  // Scroll to links
  function scrollAnchors(e, respond = null) {
    e.preventDefault()

    // Process actioned link
    var targetID = respond
      ? respond.getAttribute('href')
      : this.getAttribute('href')
    targetID.startsWith('/') && (targetID = targetID.substr(1))
    const targetAnchor = document.querySelector(targetID)

    if (!targetAnchor) return

    targetAnchor.scrollIntoView({
      behavior: 'smooth',
    })
  }
}

export default SmoothScrollAnchors
